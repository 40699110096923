<template>
  <div :class="frameless && 'frameless'">
    <div class="label" v-if="label">
      <p class="subtitle-2">{{ label }}</p>
      <span v-if="required" class="required-indicator">*</span>
      <v-icon v-if="labelIcon" class="icon" size="16">{{ labelIcon }}</v-icon>
      <slot name="append-label" />
    </div>
    <v-textarea
      v-bind="$attrs"
      v-on="$listeners"
      :outlined="!frameless"
      auto-grow
      :style="computedStyle"
      ref="textArea"
      :rules="getRules"
      v-if="multiline"
    >
      <template v-slot:message="{ message }">
        <p v-if="message" class="body-2 message">{{ message }}</p>
      </template></v-textarea
    >
    <v-text-field
      v-bind="$attrs"
      v-on="$listeners"
      :outlined="!frameless"
      :prependInnerIcon="prependInnerIcon"
      :style="computedStyle"
      ref="input"
      :rules="getRules"
      v-else
    >
      <template v-slot:message="{ message }">
        <p v-if="message" class="body-2 message">{{ message }}</p>
      </template>
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
    </v-text-field>
    <p v-if="warning" :style="computedStyle" class="body-2 warning">
      {{ warning }}
    </p>
  </div>
</template>

<script>
/**
 * @fileoverview Common component text input
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "Input",
  props: {
    label: {
      type: String,
    },
    labelIcon: {
      type: String,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
    },
    width: {
      type: String,
    },
    frameless: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: String,
    },
  },
  computed: {
    getRules() {
      const rules = this.rules;
      if (this.required) rules.push((v) => !!v || "Field is required");
      return rules;
    },
    computedStyle() {
      let computedStyle = "";
      if (this.width) {
        const spacing = this.prependInnerIcon ? 65 : 25;
        computedStyle = `width: calc(${this.width} + ${spacing}px)`;
      }
      return computedStyle;
    },
  },
  methods: {
    validate() {
      return this.multiline
        ? this.$refs.textArea.validate()
        : this.$refs.input.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  &::v-deep .v-input__control {
    .v-input__slot {
      background: $qvo-color-grey-000;
      min-height: unset;
      border-radius: 8px;
      margin-bottom: 0;
      & input::placeholder {
        color: $qvo-color-grey-600;
        // subtitle-1 styles
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
      }
      & input:focus::placeholder {
        color: transparent;
      }
    }
    .v-text-field__details {
      margin-bottom: 0;
      min-height: unset;
      .v-messages {
        min-height: unset;
      }
    }
    .v-input__prepend-inner,
    .v-input__append-inner {
      margin-top: 9px; // the built-in vuetify styles add a 17px top margin
    }
    .v-input__prepend-inner {
      padding-right: 16px;
      z-index: 1;
    }
    .v-input__append-inner {
      padding-left: 16px;
    }
    .v-messages .message {
      color: $qvo-color-grey-900;
      margin-top: 8px;
    }
    .v-messages.error--text .message {
      color: $qvo-color-red-300;
    }
  }
  &:not(.v-textarea) {
    &::v-deep .v-input__control {
      .v-input__slot {
        height: 42px;
      }
    }
  }
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--is-disabled
  ) {
  &::v-deep > .v-input__control {
    & > .v-input__slot {
      fieldset {
        color: $qvo-color-grey-300;
      }
      &:hover fieldset {
        color: $qvo-color-grey-500;
      }
    }
  }
  &::v-deep > .v-input__append-outer {
    margin-top: 9px; // the built-in vuetify styles add a 17px top margin
  }
}
.v-input--is-focused:not(.v-input--is-disabled) {
  &::v-deep > .v-input__control {
    & > .v-input__slot {
      fieldset {
        color: $qvo-color-blue-400;
        border-width: 2px;
      }
    }
  }
}
.theme--light.v-text-field--outlined.v-input--is-disabled {
  &::v-deep > .v-input__control {
    & > .v-input__slot {
      fieldset {
        color: $qvo-color-grey-400;
        background: $qvo-color-grey-100;
      }
    }
  }
}
.theme--light.v-text-field--outlined.error--text:not(.v-input--is-disabled) {
  &::v-deep > .v-input__control {
    & > .v-input__slot {
      fieldset {
        color: $qvo-color-red-300;
      }
      .v-text-field__slot input {
        color: $qvo-color-red-300;
      }
    }
  }
}

.frameless {
  &::v-deep {
    .v-text-field > .v-input__control > .v-input__slot {
      &:before,
      &:after {
        border-style: none;
      }
    }
  }
}
.label {
  display: flex;
  align-items: flex-start;
  gap: $qvo-spacing-8;
  margin-bottom: $input-label-spacing;
  p {
    text-align: justify;
  }
  .required-indicator {
    margin: 0 $qvo-spacing-4;
    color: $qvo-color-red-300;
  }
}
.icon {
  color: $qvo-color-blue-400;
}

.warning {
  color: $qvo-color-warning;
}
</style>